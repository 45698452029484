import React from "react"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share"
import { withPrefix } from "gatsby"

import Button from "components/Button"

import { useLocale } from "context/locale"

import {
  DontBuyAdopt,
  FixWinter,
  GiveSecondChance,
  UpcycledJacketsDogApproved,
  WoshGo,
  WoshGoNubuk,
  WoshGoEveryday,
  WoshGoSport,
  FixClothes,
  Viamoda,
  DontPickHoles,
  UpcycledJacketsDogSecondEdition,
  DontBuyAdoptSecondEdition,
} from "./components"

import ShareFacebookIcon from "icons/post-share-facebook.svg"
import ShareTwitterIcon from "icons/post-share-twitter.svg"
import ShareLinkedInIcon from "icons/post-share-linkedin.svg"

import StyledPost from "./styles"

const Post = ({ data }) => {
  const { t } = useLocale()
  const isBrowser = typeof window !== "undefined"
  const location = isBrowser ? window.location.href : null

  return (
    <StyledPost>
      <div className="main-container">
        <div className="post__row">
          <div className="post__col col-3">
            <div className="post__author">
              <div
                className="post__author-image"
                style={{
                  backgroundImage: `url('${data?.author?.node?.avatar?.url}')`,
                }}
              />
              <span className="post__author-position">
                {data?.author?.node?.description}
              </span>
              <p className="post__author-name">
                {data?.author?.node?.firstName} {data?.author?.node?.lastName}
              </p>
            </div>

            <date className="post__date">
              {data?.date.split("T")[0].split("-").reverse().join(".")}
            </date>

            <div className="post__share">
              <FacebookShareButton url={location} className="post__share-item">
                <ShareFacebookIcon />
              </FacebookShareButton>
              <TwitterShareButton url={location} className="post__share-item">
                <ShareTwitterIcon />
              </TwitterShareButton>
              <LinkedinShareButton url={location} className="post__share-item">
                <ShareLinkedInIcon />
              </LinkedinShareButton>
            </div>
          </div>

          <div className="post__col col-6">
            {data?.excerpt && (
              <div
                dangerouslySetInnerHTML={{ __html: data?.excerpt }}
                className="post__excerpt"
              />
            )}
            {data.slug === "jak-zostac-changemekerem" && (
              <div className="post__cta">
                <Button
                  text="Pobierz poradnik"
                  as="link-out"
                  href={withPrefix(t("/4Fchange_poradnik_2024_ENG.pdf"))}
                  variant="download-dark"
                />
              </div>
            )}
            {data.slug === "naprawiamy-zime" ||
            data.slug === "lets-fix-winter" ? (
              <div className="post__content">
                <FixWinter />
              </div>
            ) : data.slug === "upcycled-jacket-dog-approved" ||
              data.slug === "upcycled-jackets-dog-approved" ? (
              <div className="post__content">
                <UpcycledJacketsDogApproved />
              </div>
            ) : data.slug === "nie-kupuj-adoptuj" ||
              data.slug === "adopt-dont-shop" ? (
              <div className="post__content">
                <DontBuyAdopt />
              </div>
            ) : data.slug === "punkty-zbiorki-ubran" ||
              data.slug === "clothing-collection-points" ? (
              <div className="post__content">
                <GiveSecondChance />
              </div>
            ) : data.slug === "wosh-go" ? (
              <div className="post__content">
                <WoshGo />
              </div>
            ) : data.slug === "jak-czyscic-obuwie-z-nubuku" ? (
              <div className="post__content">
                <WoshGoNubuk />
              </div>
            ) : data.slug === "jak-dbac-o-buty-w-codziennym-uzytkowaniu" ? (
              <div className="post__content">
                <WoshGoEveryday />
              </div>
            ) : data.slug === "jak-dbac-o-buty-sportowe" ? (
              <div className="post__content">
                <WoshGoSport />
              </div>
            ) : data.slug === "naprawiaj-ubrania" ||
              data.slug === "repair-instead-of-throwing-away" ? (
              <div className="post__content">
                <FixClothes />
              </div>
            ) : data.slug === "circular-design-viamoda" ? (
              <div className="post__content">
                <Viamoda />
              </div>
            ) : data.slug === "nie-szukaj-dziury-w-calym" ||
              data.slug === "dont-pick-holes-in-it" ? (
              <div className="post__content">
                <DontPickHoles slug={data.slug} />
              </div>
            ) : data.slug === "upcycled-jackets-dog-approved-ii-edycja" ||
              data.slug === "upcycled-jackets-dogs-approved-second-edition" ? (
              <div className="post__content">
                <UpcycledJacketsDogSecondEdition slug={data.slug} />
              </div>
            ) : data.slug === "nie-kupuj-adoptuj-ii" ? (
              <div className="post__content">
                <DontBuyAdoptSecondEdition slug={data.slug} />
              </div>
            ) : (
              <div
                dangerouslySetInnerHTML={{ __html: data?.content }}
                className="post__content"
              />
            )}
          </div>
        </div>
      </div>
    </StyledPost>
  )
}

export default Post
